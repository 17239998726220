import React, { useState, useEffect, useRef } from 'react';
import api from "../../services/api";
import Header from "../../components/Header";
import Banner from "../../components/Banner";
import ProductCard from "../../components/ProductCard";
import Footer from "../../components/Footer";

import { ContainerInfoText, Title } from "./style.js";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const HowToBuy = () => {

  document.title = "Elite Comandos - Trocas e Devoluções"

  return (
    <>

      <Header />
      <ContainerInfoText>
        <Container className="conShadown">
          <Title>Trocas e Devoluções</Title>
          <br />
          <h2>Política de Compra</h2>
          <br />
          <h2>Considerações Gerais:</h2>
          <p>As ocorrências que envolvem troca ou devolução devem SEMPRE ser comunicadas ao Setor de Vendas, através dos e-mails <a href="mailto:pedido@elitecomandos.com.br">pedido@elitecomandos.com.br</a> ou <a href="mailto:atendimento@elitecomandos.com.br">atendimento@elitecomandos.com.br</a> ou se preferir, pelos números (47) 3281-0107 / (47) 3281-0108 de segunda à sexta-feira das 07:30 às 12:00 e das 13:12 às 17:30 hrs. O produto devolvido sem a comunicação e autorização do setor de VENDAS será reenviado ao cliente sem consulta prévia.</p>

          <h2>Devolução por Arrependimento / Desistência</h2>
          <p>Se ao receber o produto, você resolver devolvê-lo por arrependimento, deverá fazê-lo em até sete dias corridos, a contar da data de recebimento e com autorização do setor de Vendas, sem esta autorização não receberemos a mercadoria e será reenviada sem consulta prévia. Caso autorizado deverá observar as seguintes condições:</p>
          <ul>
            <li>O produto não poderá ter indícios de uso;</li>
            <li>O produto deverá ser encaminhado preferencialmente na embalagem original, acompanhado de nota fiscal;</li>
            <li>Ao efetuar o processo de devolução o cliente deverá no verso da nota fiscal a ser devolvida, informar o motivo da recusa/devolução, o nome de quem está devolvendo, CPF e a data da devolução;</li>
            <li>Neste caso o frete de devolução fica por conta de quem está devolvendo a mercadoria;</li>
          </ul>
          <p>Se ao receber o produto, você identificar qualquer defeito comunique imediatamente nosso Setor de Vendas, através dos e-mails e telefones acima informados, para orientação dos procedimentos a serem adotados. Para efetuar a troca ou devolução do produto com defeito, deverão ser observadas as condições acima expostas.</p>
          <br />
          <h2>Falta de mercadoria</h2>
          <p>Todas as ocorrências que envolvam falta de mercadoria em relação a NF devem ser comunicadas a empresa através dos e-mails e telefones acima mencionados no prazo de até 7 (sete) dias, a contar da data de entrega da mercadoria pela transportadora, para orientação dos procedimentos a serem adotados.</p>

          <h2>Avaria ou violação na embalagem</h2>
          <p>O manuseio e transporte de nossos produtos é realizado de forma a preservar a qualidade original do produto. Se na entrega do produto houver avaria aparente ou a embalagem estiver violada você deverá conferir toda a mercadoria no recebimento. Se houver algum desacordo, a mesma deverá ser recusada e o nosso setor de vendas deverá ser informado imediatamente.</p>

          <p>Caso um terceiro autorizado (porteiro, empregado, recepção, etc.) receba a entrega do seu pedido ele deverá estar apto a checar as condições do produto recebido e recusar caso haja desacordos.</p>

          <p>Não nos responsabilizamos por mercadorias recebidas com embalagem violada ou com indício de violação, caso haja desacordos e não for feita a conferência no recebimento.</p>
          <br />
          <h2>Recusa no ato da entrega:</h2>
          <p>No ato da entrega do pedido informe ao transportador o motivo da recusa e anote o mesmo através na NF e assine a mesma. Seu produto será devolvido ao nosso centro de distribuição. É de extrema importância você comunicar nosso setor de vendas imediatamente sobre esta recusa.</p>
          <br />
          <h2>Postagem do produto com defeito:</h2>
          <p>Após solicitar a devolução de um produto em nosso setor de VENDAS, você receberá por e-mail um código de postagem dos Correios.</p>

          <p>Basta levar o produto até a agência mais próxima. Esse processo é gratuito.</p>
          <br />
          <h2>Para postar o produto siga as instruções:</h2>
          <ul>
            <li>Acondicione o produto em sua embalagem original ou similar;</li>
            <li>Devolva todos os itens/acessórios que acompanham o produto;</li>
            <li>Junte a segunda via do DANFE (Documento Auxiliar da Nota Fiscal Eletrônica) recebida no ato da entrega. É imprescindível que ele seja enviado para que o produto seja identificado;</li>
            <li>Relate, no verso do DANFE, o motivo da devolução.</li>
          </ul>
          <br />
          <h2>Reembolso/Reenvio Mercadoria</h2>
          <p>Para solicitação de reembolso e reenvio de mercadoria entrar em contato com nosso setor de vendas para orientação dos procedimentos a serem adotados.</p>

          <p>Maiores informações consulte nossas atendentes.</p>
          <br />
        </Container>
      </ContainerInfoText>
      <Footer />
    </>
  );
}

export default HowToBuy;
