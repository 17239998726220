import React, { useState, useEffect, useRef } from 'react';
import api from "../../services/api";
import Header from "../../components/Header";
import Banner from "../../components/Banner";
import ProductCard from "../../components/ProductCard";
import Footer from "../../components/Footer";

import { ContainerInfoText, Title } from "./style.js";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const AboutUs = () => {

  document.title = "Elite Comandos - Sobre Nós"


  return (
    <>

      <Header />
      <ContainerInfoText>
        <Container className="conShadown">
          <Title>Sobre Nós</Title>
          <br />
          <h2>Empresa</h2>
          <p>
            A Elite Comandos possui uma linha ampla de equipamentos militares de fabricação própria, produzida para as Forças Armadas, lojas de caça e pesca e de esportes radicais, por todo Brasil. A linha de artigos militares da Elite Comandos conta com os mais variados acessórios, principalmente os camuflados, para prática de treinamentos, camping, lazer e aventura. Com representação em quartéis por todo o Brasil, buscamos segurança e conforto, tendo a qualidade e durabilidade de seus produtos como maior diferencial.
          </p>
          <br />
          <p>
            A Elite Comandos é uma marca consolidada no meio militar, que conta com a confiança de clientes de peso, como o Exército Brasileiro. Desenvolve também, produtos para a moda urbana.
          </p>
          <br />
          <p>
            Elite. Acima de Tudo, Espírito Aventureiro.
          </p>
          <br />
          <h2>Missão</h2>
          <p>
            Proporcionar ao mercado Nacional, novos produtos a preço justo e com informações precisas. Construir a cada ano um constante crescimento da empresa baseado na credibilidade e consolidação de suas atividades mercantis.
          </p>
          <br />
          <h2>Visão</h2>
          <p>
            Ser global, referência nos negócios em que atua, e empresa nº 1 de Artigos Militares do Brasil. Assim, consolidando a participação no mercado internacional.
          </p>
          <br />
          <h2>Valores</h2>
          <p>
            Respeito, confiança e lealdade por seus clientes, colaboradores, fornecedores e transparência em suas atividades.
          </p>
          <br />
          <h2>Ética</h2>
          <p>
            Esta é a base de todas as nossas ações, sobre a qual são construídos relacionamentos onde a confiança e respeito estão sempre presentes.
          </p>
          <br />
          <h2>Profissionalismo</h2>
          <p>
            Temos a responsabilidade de fabricar nossos produtos com qualidade e rapidez, honrando sempre prazos e negociações.
          </p>
          <br />
          <h2>Empreendedorismo</h2>
          <p>
            A conquista de novos mercados e a expansão de nossos negócios é importante para possibilitar investimentos em infraestrutura e tecnologia, e consequentemente a melhoria dos serviços oferecidos.
          </p>
          <br />
          <h2>Comprometimento</h2>
          <p>
            Através de nosso atendimento estabelecemos parcerias para satisfazer todas as necessidades em produtos, preços e prazos de nossos clientes. O compromisso da nossa empresa é estar acompanhando o mercado, e surpreendendo com produtos inovadores que facilitam o dia-a-dia das pessoas, e colaborem no desempenho dos profissionais que usufruem dos nossos produtos.
          </p>
          <br />
          <p>
            A Elite Comandos agradece a todos os clientes e parceiros que contribuem para o nosso sucesso!
          </p>
          <br />

        </Container>
      </ContainerInfoText>
      <Footer />
    </>
  );
}

export default AboutUs;
