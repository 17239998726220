import styled from "styled-components"

export const Button = styled.button`
    width: ${props => `${props.Width}`};
    margin: ${props => `${props.Margin}`};
    font-size: 20px;
    background: linear-gradient(0.25turn,var(--primary-color),var(--primary-color),#ef4c14);
    border: none;
    border-radius: 9px;
    background: ${(props) => `${props?.disabled ? '#db6b6c' : '#e00607;'}`};
    color: ${(props) => `${props?.disabled ? '#ccc' : '#fff'}`};
    padding: 5px;
    font-weight: 500;
    cursor: pointer;
  
    
`;
