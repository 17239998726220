import axios from "axios";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const api = axios.create({
  baseURL: baseURL,
  headers: {
      'Content-Type': 'application/json',
  },
});

api.interceptors.request.use((config) => {
  config.url += '?company=elite';

  config.headers['Authorization'] = `Bearer ${localStorage.getItem('TOKEN')}`;

  return config;
});


export default api;
