import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Product from './pages/Product';
import Account from './pages/Account';
import SearchProduct from './pages/SearchProduct';
import SearchGroup from './pages/SearchGroup';
import Cart from './pages/Cart';
import Address from './pages/Address';
import Checkout from './pages/Checkout';
import Orders from './pages/Orders';
import AboutUs from './pages/AboutUs';
import HowToBuy from './pages/HowToBuy';
import ExchangePolicy from './pages/ExchangePolicy';
import NotFound from './pages/NotFound';
import PrivacyPolicy from './pages/PrivacyPolicy';
import { CartProvider } from './utils/CartContext';

import { GlobalStyle, colors } from "./globalStyle";

function App() {
  return (
    <CartProvider>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={ <Home/> }/>
          <Route path='/product/:idDM005/:description' element={ <Product/> }/>
          <Route path='/account' element={ <Account/> }/>
          <Route path='/buscar/:search' element={ <SearchProduct/> }/>
          <Route path='/group/:id/:search' element={ <SearchGroup/> }/>
          <Route path='/cart' element={ <Cart/> }/>
          <Route path='/address' element={ <Address/> }/>
          <Route path='/checkout' element={ <Checkout/> }/>
          <Route path='/orders' element={ <Orders/> }/>
          <Route path='/aboutus' element={ <AboutUs/> }/>
          <Route path='/howtobuy' element={ <HowToBuy/> }/>
          <Route path='/exchangepolicy' element={ <ExchangePolicy/> }/>
          <Route path='/privacy-policy' element={ <PrivacyPolicy/> }/>
          
          <Route path="*" element={ <NotFound/> } />
        </Routes>
      </BrowserRouter>
    </CartProvider>
  );
}

export default App;

