import React, { useState, useEffect, useCallback } from 'react';
import { Link } from "react-router-dom";
import { cpf, cnpj } from 'cpf-cnpj-validator';
import { formatCurrencyBRL } from "./../../utils/formatCurrencyBRL";

import { verifyLogin } from "./../../utils/verifyLogin";
import api from "../../services/api";
import Header from "../../components/Header";
import ButtonDefault from "../../components/ButtonDefault";
import InputDefault from "../../components/InputDefault";
import SelectDefault from '../../components/SelectDefault';

import { useNavigate } from "react-router-dom";

import Footer from "../../components/Footer";

import { ContainerAccount, Title } from "./style.js";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { FaUserAlt, FaEdit, FaLock } from "react-icons/fa";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import InputMask from 'react-input-mask';


const Home = () => {

  document.title = "Elite Comandos"

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState([]);
  const [controllFP, setControllFP] = useState(false);

  const [nameRegister, setNameRegister] = useState('');
  const [nameSocialRegister, setNameSocialRegister] = useState('');
  const [nrCPFCNPJRegister, setNrCPFCNPJRegister] = useState('');
  const [IERegister, setIERegister] = useState('');
  const [emailRegister, setEmailRegister] = useState('');
  const [email2Register, setEmail2Register] = useState('');
  const [passRegister, setPassRegister] = useState('');
  const [confirmPassRegister, setConfirmPassRegister] = useState('');
  const [nmContact, setNmContact] = useState('');
  const [nrPhone, setNrPhone] = useState('');
  const [WhatsPhone, setWhatsPhone] = useState('S');
  const [nrPhone2, setNrPhone2] = useState('');
  const [WhatsPhone2, setWhatsPhone2] = useState('S');
  const [checkMinOrder, setCheckMinOrder] = useState('');
  const [checkMinCNPJ, setcheckMinCNPJ] = useState('');

  // endereço 
  const [addressName, setAddressName] = useState("");
  const [street, setStreet] = useState("");
  const [buildingNumber, setBuildingNumber] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [complement, setComplement] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [reference, setReference] = useState("");
  const [contact, setContact] = useState("");

  const [isLogin, setIsLogin] = useState(false);
  const [dataUser, setDataUser] = useState([]);

  const [minVl, setMinVl] = useState(0);

  const [idDM001, setIdDM001] = useState(0);

  useEffect(() => {
    if (verifyLogin()) {
      const lsClient = JSON.parse(localStorage.getItem('userDataLogin'))
      setIdDM001(lsClient.ID_DM001)
    }
  }, []);

  const RegisterClient = () => {

    if (cpf.isValid(nrCPFCNPJRegister) || cnpj.isValid(nrCPFCNPJRegister)) {
      if (checkMinOrder) {
        if (checkMinCNPJ) {
          const dataRegister = {
            "NM_CLIENTE": nameRegister,
            "NM_FANTASIA": nameSocialRegister,
            "NR_CPFCNPJ": (nrCPFCNPJRegister.replaceAll('.', '')).replaceAll('-', '').replaceAll('/', ''),
            "NR_IE": (IERegister.replaceAll('.', '')).replaceAll('-', ''),
            "DT_ALTERACAO": "",
            "ID_DM900_ALTERACAO": "",
            "DS_EMAIL": emailRegister,
            "DS_EMAIL2": email2Register,
            "DS_SENHA": passRegister,
            "NM_CONTATO": nmContact,
            "IN_SITUACAO": "",
            "IN_APROVADO": "",
            "DT_APROVADO": "",
            "ID_DM006": "",
            "ID_DM008": "",
            "NR_FONE1": nrPhone,
            "IN_WHATSAPP1": WhatsPhone,
            "NR_FONE2": nrPhone2,
            "IN_WHATSAPP2": WhatsPhone2,

            "NM_USUARIO_SISTEMA": "Site",
            "NM_DISPOSITIVO": "Site"
          }

          if (nameRegister.length > 3 && nameSocialRegister.length > 3) {
            if (nrCPFCNPJRegister.length > 10) {
              if (passRegister.length > 5 && confirmPassRegister.length) {
                if (nrPhone.length > 8) {
                  if (addressName.length > 1 && street.length > 1 && buildingNumber.length > 0 && neighborhood.length > 1 && city.length > 1 && zipCode.length > 7 && contact.length > 1) {
                    setIsLoading(true);
                    api
                      .post("create_client", dataRegister)
                      .then((response) => {
                        if (response.data) {
                          if (response.data.success) {
                            toast.success(response.data.message)

                            setNameRegister('');
                            setNameSocialRegister('');
                            setNrCPFCNPJRegister('');
                            setIERegister('');
                            setEmailRegister('');
                            setEmail2Register('');
                            setPassRegister('');
                            setConfirmPassRegister('');
                            setNmContact('');
                            setNrPhone('');
                            setWhatsPhone('S');
                            setNrPhone2('');
                            setWhatsPhone2('S');

                            const cleanedZipCode = zipCode.replace(/\D/g, '');
                            const dataAddressRegister = {
                              "ID_DM001": response.data.inserted_id,
                              "TP_ENDERECO": "1",
                              "NM_ENDERECO": addressName,
                              "DS_LOGRADOURO": street,
                              "NR_PREDIO": buildingNumber,
                              "NM_BAIRRO": neighborhood,
                              "DS_COMPLEMENTO": complement,
                              "NM_CIDADE": city,
                              "NR_CEP": cleanedZipCode,
                              "DS_REFERENCIA": reference,
                              "IN_PADRAO": "S",
                              "NM_CONTATO": contact,
                              "IN_SITUACAO": "A",
                            }

                            api
                              .post("create_client_address", dataAddressRegister)
                              .then((response) => {
                                if (response.data) {
                                  if (response.data.success) {
                                    toast.success(response.data.message)

                                    setAddressName("");
                                    setStreet("");
                                    setBuildingNumber("");
                                    setNeighborhood("");
                                    setComplement("");
                                    setCity("");
                                    setZipCode("");
                                    setReference("");
                                    setContact("");


                                  } else {
                                    toast.warning(response.data.message)
                                  }
                                } else {
                                  toast.error(response.data.message)
                                }
                              })
                              .catch((err) => {
                                toast.error('Erro, tente novamente mais tarde!')
                                console.log(err)
                              });

                            toast.warning("Aguarde aprovação da empresa!")
                          } else {
                            toast.warning(response.data.message)
                          }
                        } else {
                          toast.error(response.data.message)
                        }
                      })
                      .catch((err) => {
                        toast.error('Erro, tente novamente mais tarde!')
                        console.log(err)
                      })
                      .finally(() => {
                        setIsLoading(false);
                      })
                  } else {
                    toast.warning('Informe todos os dados de endereço!')
                  }
                } else {
                  toast.warning('Telefone inválido!')
                }
              } else {
                toast.warning('Senha inválida!')
              }
            } else {
              toast.warning('CPF/CNPJ inválidos!')
            }
          } else {
            toast.warning('Nome ou razão social muito curto!')
          }
        } else {
          toast.warning('É necessário concordar com cadastro apenas de CNPJ!')
        }
      } else {
        toast.warning('É necessário concordar com pedido mínimo!')
      }
    } else {
      toast.warning('CPF/CNPJ inválido!')
    }
  }

  const LoginClient = () => {
    if (email !== '' && password !== '') {
      const dataLogin = {
        "DS_EMAIL": email,
        "DS_SENHA": password,
      };
      
      setIsLoading(true);
      api
        .post("clint_login", dataLogin)
        .then((response) => {
          if (!response.status === 200) {
            return
          }

          if(!response.data['TOKEN']) {
            toast.error('Erro, tente novamente mais tarde!');
            return
          }         

          toast.success('Logado com sucesso');
          localStorage.setItem('TOKEN', response.data['TOKEN']);
          localStorage.setItem('userDataLogin', JSON.stringify(response.data))
          setIsLogin(true)
          setIdDM001(response.data['ID_DM001'])
          setDataUser(response.data)
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error('Erro, tente novamente mais tarde!');
          }

          if(err.response.status === 500) {
            toast.error('Erro no servidor, tente novamente mais tarde!');
          }
        })
        .finally(() => {
          setIsLoading(false);
        }
        );
    }
  };

  const recoverPassword = useCallback(() => {
    if (email !== '') {
      const dataEmailRecover = {
        "DS_EMAIL": email
      }
      setIsLoading(true);
      api
        .post("mail_recover", dataEmailRecover)
        .then(response => {
          const responseMessage = response.data.message;
          const responseSuccess = response.data.success;
          if (responseSuccess) {
            toast.success(responseMessage);
            setControllFP(false);
          } else {
            toast.error(responseMessage);
            setEmail('');
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      toast.error("Informe um e-mail para recuperar a senha!");
    }
  }, [email]);

  const forgottenPass = () => {
    setControllFP(!controllFP)
  }

  const logoutUser = () => {
    localStorage.removeItem('userDataLogin')
    setIsLogin(false)
  }

  useEffect(() => {
    const fetchData = async () => {
      const isVerified = verifyLogin();
      setIsLogin(isVerified);

      if (isVerified) {
        const dataUserLS = JSON.parse(localStorage.getItem('userDataLogin'));
        setIdDM001(dataUserLS.ID_DM001);
        setDataUser(dataUserLS);
      } else {
        const dataParms = {
          "ID_DM990": "",
          "DS_KEY_PARAMETRO": "VL_PEDIDO_MINIMO_1",
          "DS_VALOR_PARAMETRO": "",
          "DS_INF_PARAMETRO": "",
          "TP_PARAMETRO": ""
        };

        try {
          const response = await api.post("filter_parms", dataParms);
          setMinVl(response.data[0].DS_VALOR_PARAMETRO);
        } catch (err) {
          console.log(err);
        }
      }
    };

    fetchData();
  }, []);


  function applyCPFCNPJMask(inputValue) {
    const digitsOnly = inputValue.replace(/\D/g, '');
    let masked = '';

    if (digitsOnly.length <= 11) {
      // CPF
      masked = digitsOnly.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else {
      // CNPJ
      masked = digitsOnly.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    }

    return masked;
  }

  const handleChange = (e) => {
    const inputValue = e.target.value;
    const masked = applyCPFCNPJMask(inputValue);
    setNrCPFCNPJRegister(masked);
  };



  const getDataClient = () => {
    if (idDM001) {

      const dataFilterClient = {
        'ID_DM001': idDM001
      }


      api
        .post("filter_clients", dataFilterClient)
        .then((response) => {

          setNameRegister(response.data[0]['NM_CLIENTE']);
          setNameSocialRegister(response.data[0]['NM_FANTASIA']);
          setNrCPFCNPJRegister(response.data[0]['NR_CPFCNPJ']);
          setIERegister(response.data[0]['NR_IE']);
          setEmailRegister(response.data[0]['DS_EMAIL']);
          setEmail2Register(response.data[0]['DS_EMAIL2']);
          setPassRegister('');
          setConfirmPassRegister('');
          setNmContact(response.data[0]['NM_CONTATO']);
          setNrPhone(response.data[0]['NR_FONE1']);
          setWhatsPhone(response.data[0]['IN_WHATSAPP1']);
          setNrPhone2(response.data[0]['NR_FONE1']);
          setWhatsPhone2(response.data[0]['IN_WHATSAPP2']);
        })
        .catch((err) => {
          toast.error('Erro, tente novamente mais tarde!')
          console.log(err)
        });
    }
  }


  useEffect(() => {

    if (idDM001) {

      getDataClient()

    }

  }, [idDM001]);

  const updateClient = () => {
    if (cpf.isValid(nrCPFCNPJRegister) || cnpj.isValid(nrCPFCNPJRegister)) {
      const dataEdit = {
        "ID_DM001": idDM001,
        "NM_CLIENTE": nameRegister,
        "NM_FANTASIA": nameSocialRegister,
        "NR_CPFCNPJ": (nrCPFCNPJRegister.replaceAll('.', '')).replaceAll('-', '').replaceAll('/', ''),
        "NR_IE": (IERegister.replaceAll('.', '')).replaceAll('-', ''),
        "DT_ALTERACAO": "",
        "ID_DM900_ALTERACAO": "",
        "DS_EMAIL": emailRegister,
        "DS_EMAIL2": email2Register,
        "DS_SENHA": passRegister,
        "NM_CONTATO": nmContact,
        "NR_FONE1": nrPhone,
        "IN_WHATSAPP1": WhatsPhone,
        "NR_FONE2": nrPhone2,
        "IN_WHATSAPP2": WhatsPhone2,

        "NM_USUARIO_SISTEMA": "Site",
        "NM_DISPOSITIVO": "Site"
      }


      if (nameRegister.length > 3 && nameSocialRegister.length > 3) {
        if (nrCPFCNPJRegister.length > 10) {
          if (passRegister.length > 5 && confirmPassRegister.length) {
            if (nrPhone.length > 8) {
              
              setIsLoading(true);
              api
                .put("update_client", dataEdit)
                .then((response) => {
                  if (response.data) {
                    if (response.data.success) {
                      toast.success(response.data.message)
                    } else {
                      toast.warning(response.data.message)
                    }
                  } else {
                    toast.error(response.data.message)
                  }
                })
                .catch((err) => {
                  toast.error('Erro, tente novamente mais tarde!')
                  console.log(err)
                })
                .finally(() => {
                  setIsLoading(false);
                })
            } else {
              toast.warning('Telefone inválido!')
            }
          } else {
            toast.warning('Senha inválida!')
          }
        } else {
          toast.warning('CPF/CNPJ inválidos!')
        }
      } else {
        toast.warning('Nome ou razão social muito curto!')
      }
    } else {
      toast.warning('CPF/CNPJ inválido!')
    }
  }

  const handleNumericBuildingNumberInput = (event) => {
    const value = event.target.value;
    setBuildingNumber(value.replace(/[^0-9]/g, ""));
  };

  const handleNumericZipCodeInput = (event) => {
    const value = event.target.value;
    setZipCode(value.replace(/[^0-9]/g, ""));
  };

  return (
    <>

      <Header />
      <ContainerAccount>
        <Container className="conShadown">
          {!isLogin ?
            <Row>
              <Col xs={12} md={6} className="loginDiv">
                {controllFP ?
                  <>
                    <Title><FaLock />Recuperar Senha</Title>
                    <Col xs={12}>
                      <InputDefault placeholder="E-mail" onKeyPress={(e) => { if (e.key === "Enter") { recoverPassword(); } }} type="mail" Width={'100%'} value={email} onChange={e =>
                        setEmail(e.target.value)
                      } Margin={'15px 15px 15px 0px'} Text={'E-mail de Recuperação:'} />
                    </Col>
                  </>
                  :
                  <>
                    <Title><FaUserAlt />Já sou cadastrado</Title>
                    <Col xs={12}>
                      <InputDefault placeholder="E-mail" onKeyPress={(e) => { if (e.key === "Enter") { LoginClient(); } }} type="mail" Width={'100%'} value={email} onChange={e => setEmail(e.target.value)} Margin={'15px 15px 15px 0px'} Text={'E-mail:'} />
                    </Col>
                    <Col xs={12}>
                      <InputDefault placeholder="Senha" onKeyPress={(e) => { if (e.key === "Enter") { LoginClient(); } }} type='password' Width={'100%'} value={password} onChange={e => setPassword(e.target.value)} Margin={'15px 15px 15px 0px'} Text={'Senha:'} />
                    </Col>
                  </>
                }
                <Link to="" id="forgottenPass" onClick={forgottenPass}>{controllFP ? 'Voltar' : 'Esqueci minha senha'}</Link>
                <Col xs={12}>
                  {
                    controllFP ?
                      <ButtonDefault
                      disabled={isLoading}
                      Text={isLoading ? 'Enviando...' : 'Enviar'} onClick={recoverPassword} Width={'100%'} Margin={'10px 0px 0px 0px'} />
                      :
                      <ButtonDefault
                      disabled={isLoading}
                      Text={'Entrar'} onClick={LoginClient} Width={'100%'} Margin={'10px 0px 0px 0px'} />
                  }
                </Col>
              </Col>
              <Col xs={12} md={6} className="loginDiv">
                <Row>
                  <Title><FaEdit />Ainda não possuo cadastro</Title>
                  <Col xs={12}>
                    <InputDefault placeholder="Nome completo ou Razão Social" Width={'100%'} Margin={'15px 15px 15px 0px'} value={nameRegister} onChange={e => setNameRegister(e.target.value)} Text={'Nome Completo ou Razão Social:'} />
                  </Col>
                  <Col xs={12}>
                    <InputDefault placeholder="Nome Fantasia ou Apelido" Width={'100%'} Margin={'15px 15px 15px 0px'} value={nameSocialRegister} onChange={e => setNameSocialRegister(e.target.value)} Text={'Nome Fantasia ou Apelido:'} />
                  </Col>
                  <Col xs={12}>
                    <InputDefault
                      placeholder="CPF/CNPJ"
                      Width={'100%'}
                      Margin={'15px 15px 15px 0px'}
                      Text={'CPF/CNPJ:'}
                      value={nrCPFCNPJRegister}
                      onChange={handleChange}
                      maxLength={18}
                    />
                  </Col>
                  <Col xs={12}>
                    <InputMask mask="999999999999999" maskChar={null} value={IERegister} onChange={e => setIERegister(e.target.value)}>
                      {() => <InputDefault placeholder="Inscrição Estadual" Width={'100%'} Margin={'15px 15px 15px 0px'} Text={'IE:'} />}
                    </InputMask>
                  </Col>
                  <Col xs={12}>
                    <InputDefault placeholder="E-mail" type="mail" Width={'100%'} value={emailRegister} onChange={e => setEmailRegister(e.target.value)} Margin={'15px 15px 15px 0px'} Text={'E-mail:'} />
                  </Col>
                  <Col xs={12}>
                    <InputDefault placeholder="E-mail Secundário" type="mail" Width={'100%'} value={email2Register} onChange={e => setEmail2Register(e.target.value)} Margin={'15px 15px 15px 0px'} Text={'E-mail Secundário:'} />
                  </Col>
                  <Col xs={12}>
                    <InputDefault placeholder="Senha" type='password' Width={'100%'} Margin={'15px 15px 15px 0px'} value={passRegister} onChange={e => setPassRegister(e.target.value)} Text={'Senha:'} />
                  </Col>
                  <Col xs={12}>
                    <InputDefault placeholder="Repetir Senha" type='password' Width={'100%'} Margin={'15px 15px 15px 0px'} value={confirmPassRegister} onChange={e => setConfirmPassRegister(e.target.value)} Text={'Repetir Senha:'} />
                  </Col>
                  <Col xs={12}>
                    <InputDefault placeholder="Nome para Contato" type='text' Width={'100%'} Margin={'15px 15px 15px 0px'} value={nmContact} onChange={e => setNmContact(e.target.value)} Text={'Contato:'} />

                  </Col>
                  <Col xs={8}>
                    <InputMask mask="(99) 99999-9999" maskChar={null} value={nrPhone} onChange={e => setNrPhone(e.target.value)}>
                      {() => <InputDefault placeholder="Telefone" type="tel" Width={'100%'} Margin={'15px 15px 15px 0px'} Text={'Telefone:'} />}
                    </InputMask>
                  </Col>
                  <Col xs={4}>
                    <SelectDefault Width={'100%'} Margin={'20px 0px 15px 0px'} value={WhatsPhone} onChange={e => setWhatsPhone(e.target.value)} Text={'É WhatsApp?'}>
                      <option value="S">Sim</option>
                      <option value="N">Não</option>
                    </SelectDefault>
                  </Col>
                  <Col xs={8}>
                    <InputMask mask="(99) 99999-9999" maskChar={null} value={nrPhone2} onChange={e => setNrPhone2(e.target.value)}>
                      {() => <InputDefault placeholder="Telefone Secundário" type="tel" Width={'100%'} Margin={'15px 15px 15px 0px'} Text={'Telefone Secundário:'} />}
                    </InputMask>
                  </Col>
                  <Col xs={4}>
                    <SelectDefault Width={'100%'} Margin={'20px 0px 15px 0px'} value={WhatsPhone2} onChange={e => setWhatsPhone2(e.target.value)} Text={'É WhatsApp?'}>
                      <option value="S">Sim</option>
                      <option value="N">Não</option>
                    </SelectDefault>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Title>Cadastro de Endereço</Title>
                  <Col sm={12}>
                    <InputDefault Text="Nome do Endereço:" Width={'100%'} value={addressName} onChange={e => setAddressName(e.target.value)} placeholder="Ex: Galpão, Matriz..." />
                  </Col>
                  <Col sm={12}>
                    <InputDefault Text="CEP:" Width={'100%'} value={zipCode} onChange={handleNumericZipCodeInput} maxLength={11} placeholder="CEP" />
                  </Col>
                  <Col sm={12}>
                    <InputDefault Text="Cidade:" Width={'100%'} value={city} onChange={e => setCity(e.target.value)} placeholder="Cidade" />
                  </Col>
                  <Col sm={12}>
                    <InputDefault Text="Bairro:" Width={'100%'} value={neighborhood} onChange={e => setNeighborhood(e.target.value)} placeholder="Bairro" />
                  </Col>
                  <Col sm={12}>
                    <InputDefault Text="Rua:" Width={'100%'} value={street} onChange={e => setStreet(e.target.value)} placeholder="Rua" />
                  </Col>
                  <Col sm={12}>
                    <InputDefault Text="Número do Prédio:" Width={'100%'} value={buildingNumber} onChange={handleNumericBuildingNumberInput} placeholder="Nº do prédio" />
                  </Col>
                  <Col sm={12}>
                    <InputDefault Text="Complemento:" Width={'100%'} value={complement} onChange={e => setComplement(e.target.value)} placeholder="Complemento" />
                  </Col>
                  <Col sm={12}>
                    <InputDefault Text="Referência:" Width={'100%'} value={reference} onChange={e => setReference(e.target.value)} placeholder="Referência" />
                  </Col>
                  <Col sm={12}>
                    <InputDefault Text="Contato:" Width={'100%'} value={contact} onChange={e => setContact(e.target.value)} placeholder="Contato" />
                  </Col>

                  <Col xs={12}>
                  <label style={{ fontWeight: '600' }}>{minVl > 0 ? `O pedido mínimo para a primeira compra é de ${formatCurrencyBRL(minVl)}` : `Li e concordo com a política de vendas da empresa`}:</label>
                    <div className='checkTerms'>
                      <input type="checkbox" onChange={e => setCheckMinOrder(e.target.value)} id="scales" name="scales" />
                      <label for="scales" translate="no">Concordo</label>
                    </div>
                  </Col>
                  <Col xs={12} style={{ marginTop: '15px' }}>
                    <label style={{ fontWeight: '600' }}>É obrigatório possuir CNPJ para ser um revendedor Elite Comandos:</label>
                    <div className='checkTerms'>
                      <input type="checkbox" id="scales1" onChange={e => setcheckMinCNPJ(e.target.value)} name="scales" />
                      <label for="scales1" translate="no">Concordo</label>
                    </div>
                  </Col>

                  <Col xs={12} style={{ marginTop: '15px' }}>
                    <ButtonDefault 
                    disabled={isLoading}
                    Text={isLoading ? 'Enviando...' : 'Cadastrar'}
                    onClick={RegisterClient} Width={'100%'} />
                  </Col>
                </Row>

              </Col>
            </Row>
            :
            <>
              <Row>
                <Col xs={12} md={6} className='menuUser'>
                  <Title>Bem Vindo (a) {dataUser.NM_CLIENTE}</Title>
                  <p><Link to="/orders">Meus Pedidos</Link></p>
                  <p><Link to="/address">Endereços</Link></p>
                  <p onClick={logoutUser}><Link to="">Sair</Link></p>
                </Col>
                <Col xs={12} md={6} className=''>
                  <Title>Dados Cadastrais</Title>
                  <Row>
                    <Col xs={12}>
                      <InputDefault placeholder="Nome completo ou Razão Social" Width={'100%'} Margin={'15px 15px 15px 0px'} value={nameRegister} onChange={e => setNameRegister(e.target.value)} Text={'Nome Completo ou Razão Social:'} />
                    </Col>
                    <Col xs={12}>
                      <InputDefault placeholder="Nome Fantasia ou Apelido" Width={'100%'} Margin={'15px 15px 15px 0px'} value={nameSocialRegister} onChange={e => setNameSocialRegister(e.target.value)} Text={'Nome Fantasia ou Apelido:'} />
                    </Col>
                    <Col xs={12}>
                      <InputDefault
                        placeholder="CPF/CNPJ"
                        Width={'100%'}
                        Margin={'15px 15px 15px 0px'}
                        Text={'CPF/CNPJ:'}
                        value={nrCPFCNPJRegister}
                        onChange={handleChange}
                        maxLength={18}
                      />
                    </Col>
                    <Col xs={12}>
                      <InputMask mask="999999999999999" maskChar={null} value={IERegister} onChange={e => setIERegister(e.target.value)}>
                        {() => <InputDefault placeholder="Inscrição Estadual" Width={'100%'} Margin={'15px 15px 15px 0px'} Text={'IE:'} />}
                      </InputMask>
                    </Col>
                    <Col xs={12}>
                      <InputDefault placeholder="E-mail" type="mail" Width={'100%'} value={emailRegister} onChange={e => setEmailRegister(e.target.value)} Margin={'15px 15px 15px 0px'} Text={'E-mail:'} />
                    </Col>
                    <Col xs={12}>
                      <InputDefault placeholder="E-mail Secundário" type="mail" Width={'100%'} value={email2Register} onChange={e => setEmail2Register(e.target.value)} Margin={'15px 15px 15px 0px'} Text={'E-mail Secundário:'} />
                    </Col>
                    <Col xs={12}>
                      <InputDefault placeholder="Senha" type='password' Width={'100%'} Margin={'15px 15px 15px 0px'} value={passRegister} onChange={e => setPassRegister(e.target.value)} Text={'Senha:'} />
                    </Col>
                    <Col xs={12}>
                      <InputDefault placeholder="Repetir Senha" type='password' Width={'100%'} Margin={'15px 15px 15px 0px'} value={confirmPassRegister} onChange={e => setConfirmPassRegister(e.target.value)} Text={'Repetir Senha:'} />
                    </Col>
                    <Col xs={12}>
                      <InputDefault placeholder="Nome para Contato" type='text' Width={'100%'} Margin={'15px 15px 15px 0px'} value={nmContact} onChange={e => setNmContact(e.target.value)} Text={'Contato:'} />

                    </Col>
                    <Col xs={8}>
                      <InputMask mask="(99) 99999-9999" maskChar={null} value={nrPhone} onChange={e => setNrPhone(e.target.value)}>
                        {() => <InputDefault placeholder="Telefone" type="tel" Width={'100%'} Margin={'15px 15px 15px 0px'} Text={'Telefone:'} />}
                      </InputMask>
                    </Col>
                    <Col xs={4}>
                      <SelectDefault Width={'100%'} Margin={'20px 0px 15px 0px'} value={WhatsPhone} onChange={e => setWhatsPhone(e.target.value)} Text={'É WhatsApp?'}>
                        <option value="S">Sim</option>
                        <option value="N">Não</option>
                      </SelectDefault>
                    </Col>
                    <Col xs={8}>
                      <InputMask mask="(99) 99999-9999" maskChar={null} value={nrPhone2} onChange={e => setNrPhone2(e.target.value)}>
                        {() => <InputDefault placeholder="Telefone Secundário" type="tel" Width={'100%'} Margin={'15px 15px 15px 0px'} Text={'Telefone Secundário:'} />}
                      </InputMask>
                    </Col>
                    <Col xs={4}>
                      <SelectDefault Width={'100%'} Margin={'20px 0px 15px 0px'} value={WhatsPhone2} onChange={e => setWhatsPhone2(e.target.value)} Text={'É WhatsApp?'}>
                        <option value="S">Sim</option>
                        <option value="N">Não</option>
                      </SelectDefault>
                    </Col>


                    <Col xs={12} style={{ marginTop: '15px' }}>
                      <ButtonDefault
                      disabled={isLoading}
                      Text={isLoading ? "Atualizando..." : "Atualizar"}
                      onClick={updateClient} Width={'100%'} />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>


          }
        </Container>
      </ContainerAccount>
      <Footer />
      <ToastContainer />
    </>
  );
}

export default Home;
